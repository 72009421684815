import { type DocumentNode } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import {
  BrandAppleSilhouette,
  BrandGoogle,
  BrandLinkedin,
  Button,
  ButtonColor,
  ButtonWidth,
  ToastDuration,
  ToastType,
  useToast,
} from '@leland-dev/leland-ui-library';
import { useRouter } from 'next/router';
import React, { type FC, useCallback, useEffect, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import {
  AuthTarget,
  type ReferralType,
} from '../../__generated-gql-types__/globalTypes';
import { useAuth } from '../../context/AuthContext';
import { type AuthContextUserFragment } from '../../context/__generated-gql-types__/AuthContext.generated';
import { getAttributionParams } from '../../utils/analytics/attribution';
import { AUTH_REDIRECT_URI } from '../../utils/constants';
import { useDebouncedCallback } from '../../utils/hooks/useDebounce';
import { REFERRAL_TYPE_MAP_REVERSE } from '../../utils/referrals';
import { BASE_SEARCH_PATH } from '../../utils/search';
import {
  AUTH_CARD_EMAIL_KEY,
  FIRST_NAME_KEY,
  LAST_NAME_KEY,
  LelandSessionStorage,
  REFERRAL_CODE_KEY,
  REFERRAL_TYPE_KEY,
  RETURN_TO_KEY,
} from '../../utils/storage';
import { getUrlString } from '../../utils/url';
import { ErrorMessage } from '../forms/Inputs';
import Input from '../inputs/Input';

import {
  useAuthCardEmailInLelandLazyQuery,
  useAuthCardPostAuth0ActionMutation,
} from './__generated-gql-types__/AuthCard.generated';

const REGISTER_OPTIONS = Object.freeze({
  EMAIL: {
    required: {
      value: true,
      message: 'Email is required',
    },
    // Delegate email pattern matching to browser via type=email
  },
  FIRST_NAME: {
    required: {
      value: true,
      message: 'First name is required',
    },
    pattern: {
      value: /^(?!\s*$).+/gm,
      message: 'First name cannot be empty',
    },
  },
  LAST_NAME: {
    required: {
      value: true,
      message: 'Last name is required',
    },
    pattern: {
      value: /^(?!\s*$).+/gm,
      message: 'Last name cannot be empty',
    },
  },
});

const NEW_USER_BUFFER = 30 * 1000; // 30 seconds

interface AuthCardFormValues {
  email: string;
  firstName: string;
  lastName: string;
}

interface AuthCardProps {
  screenHint: 'signup' | 'login';
  className?: string;
  isModal?: boolean;
  onApplicantModalLogin?: () => void;
  onCoachModalLogin?: () => void;
  refetchQueries?: DocumentNode[];
  returnTo?: string;
  redeemCode?: string;
  referralCode?: string;
  referralType?: string;
}

export const AuthCard: FC<AuthCardProps> = ({
  screenHint,
  className = '',
  isModal = false,
  onApplicantModalLogin,
  onCoachModalLogin,
  refetchQueries,
  returnTo,
  redeemCode,
  referralCode,
  referralType,
}) => {
  const router = useRouter();
  const { showToast } = useToast();
  const { setCurrentUser, trackSignUp } = useAuth();
  const { error: auth0Error, loginWithRedirect, loginWithPopup } = useAuth0();
  const [error, setError] = useState<string | null>(null);

  const [
    getEmailInLeland,
    { data: emailCheckData, loading: loadingEmailCheck },
  ] = useAuthCardEmailInLelandLazyQuery({ notifyOnNetworkStatusChange: true });

  const emailExists = !!emailCheckData?.emailInLeland;

  const emailInSessionStorage =
    LelandSessionStorage.getItem<string>(AUTH_CARD_EMAIL_KEY);
  const firstNameInSessionStorage =
    LelandSessionStorage.getItem<string>(FIRST_NAME_KEY);
  const lastNameInSessionStorage =
    LelandSessionStorage.getItem<string>(LAST_NAME_KEY);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<AuthCardFormValues>({
    defaultValues: {
      email: emailInSessionStorage ?? undefined,
      firstName: firstNameInSessionStorage ?? undefined,
      lastName: lastNameInSessionStorage ?? undefined,
    },
  });

  const [postAuth0Action] = useAuthCardPostAuth0ActionMutation();

  const onPostModalLogin = (user: Nullable<AuthContextUserFragment>) => {
    if (user?.applicant && onApplicantModalLogin) {
      onApplicantModalLogin();
    } else if (user?.coach && onCoachModalLogin) {
      onCoachModalLogin();
    }
  };

  const handlePostAuth0Action = async (
    firstName?: string,
    lastName?: string,
  ): Promise<Nullable<AuthContextUserFragment>> => {
    const { data, errors } = await postAuth0Action({
      variables: {
        target: AuthTarget.APPLICANT,
        firstName: firstName,
        lastName: lastName,
        userSecrets: {
          firstContact: getAttributionParams(),
        },
        redeemCode,
        referralCode,
        referralType:
          (REFERRAL_TYPE_MAP_REVERSE[referralType ?? ''] as ReferralType) ??
          undefined,
      },
      refetchQueries,
    });
    if (errors) {
      console.warn(errors);
      setError('Authentication failure! Please try again');
      return null;
    }
    if (!data?.postAuth0Action) {
      // No data present, which means multiple auth0 identities just linked.
      // Re-issue the right token with the primary auth0 identity.
      return handlePostAuth0Action();
    } else {
      // Update auth data cache with the current sign-in user
      setCurrentUser(data.postAuth0Action);
      if (Date.now() - data.postAuth0Action.createdAt < NEW_USER_BUFFER) {
        trackSignUp(
          data.postAuth0Action.id,
          data.postAuth0Action.applicant?.id,
        );
      }
      return data.postAuth0Action;
    }
  };

  const handlePasswordAuthWithRedirect: SubmitHandler<AuthCardFormValues> =
    useCallback(
      async ({ email, firstName, lastName }) => {
        if (isSubmitting) return;
        const redirectUri = getUrlString(AUTH_REDIRECT_URI, {
          ...router.query,
          [REFERRAL_CODE_KEY]: referralCode,
          [REFERRAL_TYPE_KEY]: referralType,
          [FIRST_NAME_KEY]: firstName,
          [LAST_NAME_KEY]: lastName,
          [RETURN_TO_KEY]: returnTo ?? BASE_SEARCH_PATH,
        });
        await loginWithRedirect({
          authorizationParams: {
            redirect_uri: redirectUri,
            connection: 'Username-Password-Authentication',
            screen_hint: screenHint,
            login_hint: email,
          },
        });
      },
      [
        isSubmitting,
        loginWithRedirect,
        referralCode,
        referralType,
        returnTo,
        router.query,
        screenHint,
      ],
    );

  const handlePasswordAuthWithPopup: SubmitHandler<
    AuthCardFormValues
  > = async ({ email, firstName, lastName }) => {
    if (isSubmitting) return;
    await loginWithPopup({
      authorizationParams: {
        connection: 'Username-Password-Authentication',
        screen_hint: screenHint,
        login_hint: email,
      },
    });
    const user = await handlePostAuth0Action(firstName, lastName);
    onPostModalLogin(user);
  };

  const isSignup = screenHint == 'signup';
  const isReferred = referralCode && referralType;

  const handlePasswordAuth: SubmitHandler<AuthCardFormValues> = async ({
    email,
    firstName,
    lastName,
  }) => {
    if (isSubmitting) return;

    if (!isSignup && !emailExists) {
      LelandSessionStorage.setItem(AUTH_CARD_EMAIL_KEY, email);
      if (isModal) {
        setError(
          'No account found with this email address. Please create an account.',
        );
        return;
      } else {
        showToast({
          type: ToastType.WARNING,
          message:
            'No account found with this email address. Please create an account.',
          duration: ToastDuration.NORMAL,
        });
        return router.replace('/signup');
      }
    } else if (isSignup && emailExists) {
      LelandSessionStorage.setItem(AUTH_CARD_EMAIL_KEY, email);
      const existingAccountMessage =
        isReferred && !emailExists
          ? 'Referrals are for users without existing Leland accounts.'
          : 'There’s an account with this email address. Please log in.';

      if (isModal) {
        setError(existingAccountMessage);
        return;
      } else {
        showToast({
          type: ToastType.WARNING,
          message: existingAccountMessage,
          duration: ToastDuration.NORMAL,
        });
        return router.replace('/login');
      }
    } else {
      if (isModal) {
        return handlePasswordAuthWithPopup({ email, firstName, lastName });
      } else {
        return handlePasswordAuthWithRedirect({ email, firstName, lastName });
      }
    }
  };

  const handleSocialAuthRedirect = async (connection: string) => {
    return await loginWithRedirect({
      authorizationParams: {
        redirect_uri: getUrlString(AUTH_REDIRECT_URI, {
          ...router.query,
          [REFERRAL_CODE_KEY]: referralCode,
          [REFERRAL_TYPE_KEY]: referralType,
          [RETURN_TO_KEY]: returnTo ?? BASE_SEARCH_PATH,
        }),
        connection: connection,
      },
    });
  };

  const handleSocialAuthPopup = async (connection: string) => {
    await loginWithPopup({
      authorizationParams: {
        connection: connection,
      },
    });
    const user = await handlePostAuth0Action();
    onPostModalLogin(user);
  };

  const checkIfEmailExists = useCallback(
    async (email: string) => getEmailInLeland({ variables: { email: email } }),
    [getEmailInLeland],
  );

  const debouncedCheckIfEmailExists = useDebouncedCallback(
    checkIfEmailExists,
    100,
  );

  const watchEmail = watch('email');
  useEffect(() => {
    void debouncedCheckIfEmailExists(watchEmail);
  }, [debouncedCheckIfEmailExists, watchEmail]);

  const disabled = loadingEmailCheck || isSubmitting;

  return (
    <>
      <div className={`grid gap-3 ${className}`}>
        <Button
          label="Continue with Google"
          LeftIcon={BrandGoogle}
          buttonColor={ButtonColor.WHITE}
          width={ButtonWidth.FULL}
          onClick={() =>
            isModal
              ? handleSocialAuthPopup('google-oauth2')
              : handleSocialAuthRedirect('google-oauth2')
          }
        />
        <Button
          label="Continue with LinkedIn"
          LeftIcon={BrandLinkedin}
          buttonColor={ButtonColor.WHITE}
          width={ButtonWidth.FULL}
          onClick={() =>
            isModal
              ? handleSocialAuthPopup('linkedin')
              : handleSocialAuthRedirect('linkedin')
          }
        />
        <Button
          label="Continue with Apple"
          LeftIcon={BrandAppleSilhouette}
          buttonColor={ButtonColor.WHITE}
          width={ButtonWidth.FULL}
          onClick={() =>
            isModal
              ? handleSocialAuthPopup('apple')
              : handleSocialAuthRedirect('apple')
          }
        />
        <div className="relative flex w-full items-center justify-center">
          <hr
            className={`${
              isModal ? 'my-6' : 'my-8'
            } h-px w-full border-0 bg-gray-200`}
          />
          <span className="absolute left-1/2 -translate-x-1/2 bg-white px-2 text-lg text-leland-gray-light">
            or
          </span>
        </div>
        <form
          data-form-name="auth-card-form"
          className="grid gap-3"
          onSubmit={handleSubmit(handlePasswordAuth)}
        >
          {isSignup ? (
            <div className="flex flex-col lg:flex-row">
              {/* Name */}
              <Input
                label="First name"
                type="text"
                placeholder="First name"
                labelIsHidden
                className="flex-1 lg:mr-3"
                register={register('firstName', REGISTER_OPTIONS.FIRST_NAME)}
                error={errors.firstName}
              />
              <Input
                label="Last name"
                type="text"
                placeholder="Last name"
                labelIsHidden
                className="mt-3 flex-1 lg:mt-0"
                register={register('lastName', REGISTER_OPTIONS.LAST_NAME)}
                error={errors.lastName}
              />
            </div>
          ) : null}
          {/* Email */}
          <Input
            label="Email"
            type="email"
            placeholder="Email address"
            labelIsHidden
            register={register('email', REGISTER_OPTIONS.EMAIL)}
            error={errors.email}
          />
          <Button
            label={isSignup ? 'Create an account' : 'Log in'}
            buttonColor={ButtonColor.PRIMARY}
            data-control-name="submit-auth-card-form"
            type="submit"
            disabled={disabled}
          />
          {(error ?? auth0Error) ? (
            <ErrorMessage errorMessage={error ?? 'Please try again'} />
          ) : null}
        </form>
      </div>
    </>
  );
};
