// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import { AuthContextUserFragmentDoc } from '../../../context/__generated-gql-types__/AuthContext.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuthCardPostAuth0ActionMutationVariables = Types.Exact<{
  target: Types.AuthTarget;
  firstName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  lastName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  userSecrets?: Types.InputMaybe<Types.UserSecretsInput>;
  redeemCode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  referralCode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  referralType?: Types.InputMaybe<Types.ReferralType>;
}>;

export type AuthCardPostAuth0ActionMutation = {
  __typename?: 'Mutation';
  postAuth0Action?: {
    __typename?: 'User';
    id: string;
    createdAt: number;
    firstName: string;
    lastName: string;
    pictureLink?: string | null;
    email: string;
    applicant?: {
      __typename?: 'Applicant';
      id: string;
      newExperience?: boolean | null;
      moneyCreditAvailable: number;
      timeCreditAvailable: number;
      upcomingEnrolledSessions?: Array<{
        __typename?: 'CoachingSession';
        id: string;
        startAt: number;
        endAt: number;
        content:
          | {
              __typename?: 'Class';
              id: string;
              name?: string | null;
              slug?: string | null;
              isFreeEvent?: boolean | null;
            }
          | { __typename?: 'OneOnOneSession'; title: string };
        coach: {
          __typename?: 'Coach';
          id: string;
          user: { __typename?: 'User'; id: string; firstName: string };
        };
      }> | null;
      lelandPlus?: { __typename?: 'LelandPlus'; active: boolean } | null;
    } | null;
    coach?: { __typename?: 'Coach'; id: string } | null;
    phoneNumber?: {
      __typename?: 'PhoneNumber';
      countryCode: string;
      callingCountryCode: string;
      nationalFormat: string;
    } | null;
    impersonator?: { __typename?: 'User'; id: string } | null;
    userSecrets?: {
      __typename?: 'UserSecrets';
      id: string;
      firstContact?: {
        __typename?: 'FirstContact';
        gclid?: string | null;
        source?: string | null;
        medium?: string | null;
        campaign?: string | null;
        referrer?: string | null;
      } | null;
    } | null;
    source?: { __typename?: 'UserSource'; type: Types.UserSourceType } | null;
  } | null;
};

export type AuthCardEmailInLelandQueryVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;

export type AuthCardEmailInLelandQuery = {
  __typename?: 'Query';
  emailInLeland: string;
};

export const AuthCardPostAuth0ActionDocument = gql`
  mutation AuthCard_PostAuth0Action(
    $target: AuthTarget!
    $firstName: String
    $lastName: String
    $userSecrets: UserSecretsInput
    $redeemCode: String
    $referralCode: String
    $referralType: ReferralType
  ) {
    postAuth0Action(
      target: $target
      firstName: $firstName
      lastName: $lastName
      userSecrets: $userSecrets
      redeemCode: $redeemCode
      referralCode: $referralCode
      referralType: $referralType
    ) {
      id
      createdAt
      applicant {
        id
      }
      coach {
        id
      }
      ...AuthContext_User
    }
  }
  ${AuthContextUserFragmentDoc}
`;
export type AuthCardPostAuth0ActionMutationFn = Apollo.MutationFunction<
  AuthCardPostAuth0ActionMutation,
  AuthCardPostAuth0ActionMutationVariables
>;

/**
 * __useAuthCardPostAuth0ActionMutation__
 *
 * To run a mutation, you first call `useAuthCardPostAuth0ActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthCardPostAuth0ActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authCardPostAuth0ActionMutation, { data, loading, error }] = useAuthCardPostAuth0ActionMutation({
 *   variables: {
 *      target: // value for 'target'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      userSecrets: // value for 'userSecrets'
 *      redeemCode: // value for 'redeemCode'
 *      referralCode: // value for 'referralCode'
 *      referralType: // value for 'referralType'
 *   },
 * });
 */
export function useAuthCardPostAuth0ActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthCardPostAuth0ActionMutation,
    AuthCardPostAuth0ActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthCardPostAuth0ActionMutation,
    AuthCardPostAuth0ActionMutationVariables
  >(AuthCardPostAuth0ActionDocument, options);
}
export type AuthCardPostAuth0ActionMutationHookResult = ReturnType<
  typeof useAuthCardPostAuth0ActionMutation
>;
export type AuthCardPostAuth0ActionMutationResult =
  Apollo.MutationResult<AuthCardPostAuth0ActionMutation>;
export type AuthCardPostAuth0ActionMutationOptions = Apollo.BaseMutationOptions<
  AuthCardPostAuth0ActionMutation,
  AuthCardPostAuth0ActionMutationVariables
>;
export const AuthCardEmailInLelandDocument = gql`
  query AuthCard_EmailInLeland($email: String!) {
    emailInLeland(email: $email)
  }
`;

/**
 * __useAuthCardEmailInLelandQuery__
 *
 * To run a query within a React component, call `useAuthCardEmailInLelandQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthCardEmailInLelandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthCardEmailInLelandQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAuthCardEmailInLelandQuery(
  baseOptions: Apollo.QueryHookOptions<
    AuthCardEmailInLelandQuery,
    AuthCardEmailInLelandQueryVariables
  > &
    (
      | { variables: AuthCardEmailInLelandQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AuthCardEmailInLelandQuery,
    AuthCardEmailInLelandQueryVariables
  >(AuthCardEmailInLelandDocument, options);
}
export function useAuthCardEmailInLelandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthCardEmailInLelandQuery,
    AuthCardEmailInLelandQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AuthCardEmailInLelandQuery,
    AuthCardEmailInLelandQueryVariables
  >(AuthCardEmailInLelandDocument, options);
}
export function useAuthCardEmailInLelandSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AuthCardEmailInLelandQuery,
    AuthCardEmailInLelandQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AuthCardEmailInLelandQuery,
    AuthCardEmailInLelandQueryVariables
  >(AuthCardEmailInLelandDocument, options);
}
export type AuthCardEmailInLelandQueryHookResult = ReturnType<
  typeof useAuthCardEmailInLelandQuery
>;
export type AuthCardEmailInLelandLazyQueryHookResult = ReturnType<
  typeof useAuthCardEmailInLelandLazyQuery
>;
export type AuthCardEmailInLelandSuspenseQueryHookResult = ReturnType<
  typeof useAuthCardEmailInLelandSuspenseQuery
>;
export type AuthCardEmailInLelandQueryResult = Apollo.QueryResult<
  AuthCardEmailInLelandQuery,
  AuthCardEmailInLelandQueryVariables
>;
